<template>
  <div>
    <manager v-if="matchType != 'league'"></manager>
    <managerLeague v-else></managerLeague>
    <!-- <button @click="text">测试</button> -->
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import manager from "./manager";
import managerLeague from "./managerLeague";
export default {
  name: "add",
  components: {
    manager,
    managerLeague,
  },
  data() {
    return {
      matchType: "",
    };
  },
  methods:{
    text(){
      this.removeTabByPath(`/match/info/info_s1/aaatext`);
      //  this.$router.push({
      //   path: `/match/info/info_s1/aaatext`,
      //   query: {
      //    text:111
      //   },
      // });
       console.log(1111);
    },
    //   ...mapActions("tabs", [
    //   "handleTabsEdit",
    //   "addTabs",
    //   "handleTabsClick",
    //   "removeTab",
    //   "removeTabByPath",
    // ]),

     
  },
  //组件被激活时
  async activated() {
    this.matchType = localStorage.getItem("matchType");
    this.$store.commit("tabs/setVNode", this.$vnode);
  },
};
</script>

<style scoped></style>
